import React from 'react';
const nextLineEmail = "%0D%0A";
function CreabekLink({ linkData }) {

    return linkData.isInternal ? CreabekInternalLink(linkData): CreabekExternalLink(linkData);
}

function CreabekExternalLink(linkData) {
    var hasWeb = linkData.web !== null;
    if(hasWeb === false)
    {
        return (<span class={linkData.classes} >{linkData.title}</span>);
    }

    return (
        <a href={linkData.web} rel="noopener noreferrer" className={linkData.classes} target="_blank">{linkData.title}{linkData.icones}</a>
    );
}

function CreabekInternalLink(linkData) {

    return (
        <a href={linkData.web}  className={linkData.classes}>{linkData.title}{linkData.icones}</a>
    );
}

function CreabekMailToLink({mailInfo})
{

var mailTo = "mailto:admin@phenix-games.com?subject=";
mailTo+=mailInfo.subject;
mailTo+= "&cc=" + mailInfo.cc;
mailTo+="&body=Merci d'utiliser votre adresse google :)"+nextLineEmail+nextLineEmail;
mailTo+="Nom à afficher au publique :"+nextLineEmail;
mailTo+="(optionel) Site web à afficher : ";
return (
    <a class="btn btn-primary" href={mailTo}>{mailInfo.title}</a>

)

}


export { CreabekLink,CreabekMailToLink };
