import React from 'react';
import { Api, Navigation } from '@utils';

function AddGame() {
    var gameData = {};
    const update = (name, e) => {
        gameData[name] =  e.target.value ;
      }
    Navigation.SetCurrentLink('addGame');
    const addGameDB = () => {
        Api.Games.AddGame(gameData);
      };
    return (
        <>
            <section className="my-2" >
                <div className="container py-5">
                    <form  onSubmit={addGameDB}>
                        <div class="form-row">
                            <div class="col-md-10 mb-2">
                                <label for="validationDefault01">Nom du jeux</label>
                                <input type="text" value={gameData.name} class="form-control" onChange={(e) => update("name", e)}  id="validationDefault01" placeholder="Nom" required />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-10 mb-2">
                                <label for="validationDefault03">Nombre de joueurs</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 mb-2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend2">Min</span>
                                    </div>
                                    <input type="number" class="form-control" value={gameData.NBPlayersMin} onChange={(e) => update("NBPlayersMin", e)} id="validationDefaultUsername" placeholder="playersMin" aria-describedby="inputGroupPrepend2" required />
                                </div>

                            </div>
                            <div class="col-md-4 mb-2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend2">Max (facultatif)</span>
                                    </div>
                                    <input type="number"  value={gameData.NBPlayersMax} onChange={(e) => update("NBPlayersMax", e)} class="form-control" id="validationDefaultUsername" placeholder="playersMax" aria-describedby="inputGroupPrepend2" />
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-5 mb-3">
                                <label for="validationDefault03">Temps moyen d'une partie (facultatif)</label>
                                <input type="text"  value={gameData.Duration} onChange={(e) => update("Duration", e)} class="form-control" id="validationDefault03" placeholder="30 minutes" />
                            </div>
                            <div class="col-md-2 mb-3">
                                <label for="validationDefault04">Âge minimum</label>
                                <input type="number"  value={gameData.MinimumAge} onChange={(e) => update("MinimumAge", e)} class="form-control" id="validationDefault04" placeholder="ageMin" required />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-5 mb-2">
                                <label for="validationDefault03">Créateur (facultatif)</label>
                                <input type="text" value={gameData.Creator} onChange={(e) => update("Creator", e)} class="form-control" id="validationDefault03" placeholder="creator" />
                            </div>
                            <div class="col-md-5 mb-2">
                                <label for="validationDefault04">Illustrateur  (facultatif)</label>
                                <input type="text" value={gameData.Illustrator} onChange={(e) => update("Illustrator", e)} class="form-control" id="validationDefault04" placeholder="illustrator" />
                            </div>
                        </div>
                        <button class="btn btn-primary" type="submit">Ajouter</button>
                    </form>
                </div>
            </section>
        </>
    );
}

export { AddGame };
