import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from '@components'
import { Home,GameList,AddGame} from '@views'
//import { Home, GameList, EventList, MyAccounts,RessourceList,AboutUs } from '@views'

import './ressources/bootstrap-4.6.2-dist/css/bootstrap.css'
import './style.css'
// import './ressources/bootstrap-4.6.2-dist/js/bootstrap.js'

export default function App() {

  return (
<BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="games">
            <Route path="" element={<GameList  />} />
            <Route path=":viewMode" element={<GameList  />} />
            <Route path="add" element={<AddGame  />} />
          </Route>
        </Route> 
      </Routes>
    </BrowserRouter> 
  );
}