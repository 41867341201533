import React from 'react';
import { Person, CCircle } from 'react-bootstrap-icons';
import { useGoogleLogin } from '@react-oauth/google';
import {Link, Outlet } from "react-router-dom";
import { User, Navigation } from '@utils';
import { LegalPopup } from '@components';
import { Dice2Fill } from 'react-bootstrap-icons';
import logo from './Creabek.svg'

function Layout() {

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => { logInFinished(codeResponse) },
    onError: (error) => console.log('Login Failed:', error)
  });
  const logOut = () => {
    User.LogOut();
    window.location.href = "/";
  };
  const logInFinished = async (codeResponse) => {
    await User.LogIn(codeResponse.access_token);
    window.location.href = "/";
  };
  return (
    <>


      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <a className="navbar-brand" href="/"><img width="200px" height="auto" src={logo} alt="Logo" /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>


          <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0 justify-content-center ml-lg-auto">
              <li className={"nav-item" + (Navigation.GetCurrentLink() === "home" ? " active" : "")}>
                <a className="nav-link" href="/">Accueil <span className="sr-only">(actuel)</span></a>
              </li>
              
              {User.IsConnected() ? (
                <>
<div className="nav-item dropdown d-none d-lg-block">
                
                <button className="nav-link dropdown-toggle btn btn-link" href="#" data-toggle="dropdown" aria-expanded="false">
                <span>-  -  -   Menu des jeux     -  -  - <Dice2Fill/></span>      
                </button>
                <div className="dropdown-menu">
                  <Link reloadDocument={true} className={"dropdown-item" + (Navigation.GetCurrentLink() === "games" ? " active" : "")} to="/games">Les jeux (liste complète)</Link>
                  <Link reloadDocument={true} className={"dropdown-item" + (Navigation.GetCurrentLink() === "gamesPlus" ? " active" : "")} to="/games/gamesPlus">Bonifier ma ludotech</Link>
                  <Link reloadDocument={true} className={"dropdown-item" + (Navigation.GetCurrentLink() === "myGames" ? " active" : "")} to="/games/myGames">Ma ludotech</Link>
                  <Link reloadDocument={true} className={"dropdown-item" + (Navigation.GetCurrentLink() === "addGame" ? " active" : "")} to="/games/add">Ajouter un jeu</Link>
                </div>
              </div>
                </>
              ) : (<>
              
              <li className={"nav-item" + (Navigation.GetCurrentLink() === "games" ? " active" : "")}>
                <Link className="nav-link" to="/games">Liste des Jeux</Link>
              </li>
              
              </>)}
            </ul>
            {User.IsConnected() ? (

              <div className="nav-item dropdown d-none d-lg-block">
                
                <button className="nav-link dropdown-toggle btn btn-link" href="#" data-toggle="dropdown" aria-expanded="false">
                  {User.CurrentUser().googleEmail}
                </button>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="/" onClick={logOut}>se déconnecter</a>
                </div>
              </div>
            ) : (<button className="btn btn-primary my-2 my-sm-0" onClick={() => login()} ><Person /> Se connecter</button>)}
          </div>
        </div>
      </nav>

      <main>
        <div className="mt-5">
          <Outlet />
        </div>
      </main>
      <footer className="footer mt-auto py-3 text-center">
        <div className="container">
          <span className="text-muted"><CCircle /> 2023 - Phénix Games - </span><a href="/" data-toggle="modal" data-target="#legalPopup" className="legal"> Conditions d'utilisation
          </a>
        </div>
      </footer>
      <div className="modal fade" id="legalPopup" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-legal">
          <div className="modal-content">
            <div className="modal-header">
              <h3>Conditions d'utilisation</h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <LegalPopup />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { Layout };