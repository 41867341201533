import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Api, Navigation } from '@utils';
import { PageTitle, Pagination } from '@components'
import GameListRecords from './GameListRecords';
import { Filter } from 'react-bootstrap-icons';


function arrayRemove(arr, value) {
 
    return arr.filter(function (geeks) {
        return geeks.ID !== value.ID;
    });
 
}
 


function GameList() {

    const filterGamesList = () => {
      
       var filterList = gamesList;
        if(filterNbPlayerMin > 0 && filterNbPlayerMax > 0)
        {
            filterList = gamesList.filter((game) => game.NBPlayersMin <= filterNbPlayerMin && (game.NBPlayersMax == null || game.NBPlayersMax >= filterNbPlayerMax) );
        }
        else if(filterNbPlayerMin > 0 )
        {
            filterList = filterList.filter((game) =>game.NBPlayersMin <= filterNbPlayerMin &&(game.NBPlayersMax == null || game.NBPlayersMax >= filterNbPlayerMin));
           
        }
        else if(filterNbPlayerMax > 0 )
        {
            filterList = filterList.filter((game) =>game.NBPlayersMax == null || game.NBPlayersMax >= filterNbPlayerMax);
        }
        setGamesListFilter(filterList);
    }

    const [gamesList, setGamesList] = useState([]);
    const [gamesListFilter, setGamesListFilter] = useState([]);
    const [addedGame, setAddedGame] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(15);
    const [filterNbPlayerMin,setFilterNbPlayerMin] = useState(0);
    const [filterNbPlayerMax,setFilterNbPlayerMax] = useState(0);
    let { viewMode } = useParams();
if(viewMode)
{
    Navigation.CheckAccesLogin();

    Navigation.SetCurrentLink(viewMode);
}
else{
    Navigation.SetCurrentLink('games');
}
    useEffect(() => {

        if(addedGame.ID != undefined)
        {
            setGamesList(arrayRemove(gamesList, addedGame));
        }
        if (gamesList.length === 0) {
            gamesList.push({});
            var promise = Api.Games.List();
            promise.then(data => { setGamesList(data);  setGamesListFilter(data); });
           
        }
    }
    )

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = gamesListFilter.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(gamesListFilter.length / recordsPerPage);
    
    return <>
        {gamesList ? (

            
            <section className="my-2" >
                 <div className="container pb-5 py-md-5">
                  
                   <div class="form-row">
                            <div class="col-md-4 mb-2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend2">NB players Min</span>
                                    </div>
                                    <input type="number" class="form-control" value={filterNbPlayerMin} onChange={(e) => setFilterNbPlayerMin(e.target.value)} id="validationDefaultUsername" placeholder="playersMin" aria-describedby="inputGroupPrepend2" required />
                                </div>

                            </div>
                            <div class="col-md-4 mb-2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend2">NB players Max</span>
                                    </div>
                                    <input type="number"  value={filterNbPlayerMax} onChange={(e) => setFilterNbPlayerMax(e.target.value)} class="form-control" id="validationDefaultUsername" placeholder="playersMax" aria-describedby="inputGroupPrepend2" />
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-primary my-2 my-sm-0" data-toggle="tooltip" data-placement="bottom" title="" onClick={filterGamesList}><Filter /> Appliquer</button>
                </div>
                <div className="container pb-5 py-md-5">
                    <PageTitle mainPage={true} title="Liste des boards games" />
                    <GameListRecords data={currentRecords} setAddedGame={setAddedGame}/>
                    <Pagination
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            </section>
        ) : (
            <></>
        )}
    </>
}


export { GameList };
