import { User } from '@utils';

function setCurrentLink(newLink)
{

    localStorage.setItem('currentLink',newLink);
}

function checkAccess(access)
{
    if(checkAccesLogin() == false)
    {
        window.location.href = "/";
    }
    if(User.CheckAcces(access) == false)
    {
        window.location.href = "/";
    }
    
}

function checkAccessAdmin()
{
   checkAccess('admin');
}

function checkAccesLogin()
{
    if(!User.IsConnected())
    {
       window.location.assign("/");
    }
}

export const Navigation = {

    GetCurrentLink:  () => {
       return localStorage.getItem('currentLink') === null ? "home" : localStorage.getItem('currentLink');
    },
    SetCurrentLink:setCurrentLink,
    CheckAccesLogin:checkAccesLogin,
    CheckAccessAdmin:checkAccessAdmin,
    CheckAccess:checkAccess
}