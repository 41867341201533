import { googleLogout } from '@react-oauth/google';
import {Api} from '@utils'

async function login(googleToken)
{
    await Api.User.LogIn(googleToken);
}

function checkAccess(access)
{
    return localStorage.getItem('userAccess') === null ? false : localStorage.getItem('userAccess').indexOf(access) > -1;
}

export const User = {

    IsConnected:  () => {
       return localStorage.getItem('currentUser') && !localStorage.getItem("currentUser").includes("undefined");
    },
    CurrentUser: () => {
        return JSON.parse(localStorage.getItem('currentUser'));
    },
    LogIn: login,
    CheckAcces:checkAccess,
    LogOut: () => {
        googleLogout();
        localStorage.removeItem("currentUser");
        localStorage.removeItem("user");
    }
}
