import React from 'react';
import { Navigation } from '@utils';
import imageAccueil from './ck_img_intro.svg'

function Home() {
  Navigation.SetCurrentLink('home');
  return (
    <>
      <section className=" jumbotron header mt-5">
        <div className="container p-md-5 pt-sm-6">
          <div className="row d-flex align-items-center">
          <div className="col-md-7 order-md-2" >
              <img src={imageAccueil} alt="Inventaire phénix games"  width="500px" height="auto"  />
            </div>

            <div className="col-md-5 order-md-1">
              <h1>Liste des jeux pour nous tous</h1>
              <p className="lead text-muted"></p>

              <a href="/games" className="btn btn-primary my-2">Découvrez la liste des Boards games ! </a>

            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}
export { Home };