import { Api, User } from '@utils';
import React from 'react';
import { People, EmojiSmileFill, HourglassSplit, PersonFillGear, VectorPen, BuildingFillGear, PlusCircleDotted, GearFill } from 'react-bootstrap-icons';
import { useParams } from "react-router-dom";





function Game({ game, setAddedGame }) {
    let { viewMode } = useParams();
    const addGameToLudo = () => {
        Api.Games.AddGameInLudo({u:User.CurrentUser().ID,g:game.ID});
        setAddedGame(game);
    }
    function getStatusIcon() {
        if (viewMode === "gamesPlus") {
            return <button className="btn btn-primary my-2 my-sm-0" data-toggle="tooltip" data-placement="bottom" title="" onClick={addGameToLudo}><PlusCircleDotted /> Ajouter</button>
        }
        if (viewMode === "myGames") {
            return <button className="btn btn-primary my-2 my-sm-0" data-toggle="tooltip" data-placement="bottom" title="" ><GearFill /> Gérer</button>
        }

    }
    return (
        <>
            <li className="list-group-item bg-light">
                <div className="d-md-flex justify-content-between align-items-center">
                    <div>{game.Name} </div>
                    <div>

                        <button type="button" className="btn btn-primary mt-1 mt-md-0" data-toggle="modal" data-target={"#G-" + game.ID}>
                            Voir les informations du board games
                        </button>
                        {getStatusIcon()}
                    </div>
                </div>
            </li>
            <GameModal game={game} />
        </>


    );
}

function GameModal({ game }) {
    return (

        <div className="modal fade" id={"G-" + game.ID} data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">{game.Name}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row px-3">
                            <div className="col-md">
                                <small className="text-muted text-center mb-1"> Nombre de joueurs</small>
                                <p>{game.NBPlayersMin} - {game.NBPlayersMax} <People /></p>
                            </div>
                            <div className="col-md">
                                <small className="text-muted text-center mb-1">Durée</small>
                                <p>{game.Duration} <HourglassSplit /></p>
                            </div>
                            <div className="col-md">
                                <small className="text-muted text-center mb-1">Âge minimum</small>
                                <p>{game.MinimumAge} <EmojiSmileFill /></p>
                            </div>

                        </div>
                        <div className="row px-3">
                            <div className="col-md">
                                <small className="text-muted text-center mb-1"> Créateur</small>
                                <p>{game.Creator}  <PersonFillGear /></p>
                            </div>
                            <div className="col-md">
                                <small className="text-muted text-center mb-1">Illustrateur</small>
                                <p>{game.Illustrator} <VectorPen /></p>
                            </div>
                            <div className="col-md">
                                <small className="text-muted text-center mb-1">Éditeur</small>
                                <p>{game.Editor} <BuildingFillGear /></p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">

                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>

                    </div>
                </div>
            </div>
        </div>
    );
}
export { Game };
