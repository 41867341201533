import React from 'react'
import { Game } from '@components'



const GameListRecords = ({ data,setAddedGame }) => {
  
   return (<ul className="list-group list-group-flush mt-3 mt-md-5 mb-2">

      {data.map((g) => (
         <Game key={g.ID} game={g} setAddedGame={setAddedGame} />
      ))}
   </ul>
   )
}

export default GameListRecords  